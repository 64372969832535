
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
	components: {}
})
export default class CreateDocumentModal extends Vue {
	@Prop() current: any
	@Prop() folder: any

	private crumbs: any[] = []
	
	private created() {
	}
	
	@Watch('current')
	private breadcrumbList() {		
		function findPath(folders: any, id: number) {
			if (folders.id === id) return [];

			if (folders.children?.length) {
				for (let submenu of folders.children) {
					const result: any = findPath(submenu, id);
					if (result) {
						return [{ ...submenu }, ...result];
					}
				}
			}
		}
		const path = findPath(this.folder, this.current.id) || []
		path.unshift(this.folder)		
		this.crumbs = path
		this.$emit('updateBreadcrumb', path)		
	}

	private setCurrentFolder(crumb: any) {
		const el = {...crumb}
		this.$emit('setCurrentFolder', el)
	}
}
