
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'

@Component({
  components: { DefaultModal, TextInput }
})
export default class CreateFolderModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() currentFolder!: any

    private title: string = ''

    private close() {
        this.title = ''
        this.$emit('closeModal')
    }
    
    private save() {
        this.$emit('saveNewFolder', this.title)
    }
}
