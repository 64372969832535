
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: { DefaultModal }
})
export default class MoveDocumentModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() docToMove!: any
    @Prop() currentFolder!: any
    @Prop() flatFoldersList!: any
    @Prop() documents!: any[]

    private selectedParent: any = null

    private close() {
        this.selectedParent = null
        this.$emit('closeModal')
    }
    
    private save() {
        const idsToMove: number[] = []
        let add: boolean = false
        let level: any = null

        this.documents.forEach((item: any) => {
            if (this.docToMove.template_id === item.template_id) {
                add = true
                level = item.level
                idsToMove.push(item.template_id)                
            }
            else {
                if (add && item.level > level) {
                    idsToMove.push(item.template_id)
                }
                else add = false
            }
        })
        this.$emit('moveDocument', { 
            template_ids: idsToMove, 
            folder_id: this.selectedParent ? this.selectedParent.id : null 
        })      
    }

    private prepareFoldersList() {
        return this.flatFoldersList.map((item: any) => {
            item.disabled = this.currentFolder && this.currentFolder.id === item.id
            return item
        })
    }
}
