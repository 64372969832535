
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'

@Component({
  components: { DefaultModal, TextInput, DropdownInput }
})
export default class CreateDocumentModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() currentFolder!: any
    @Prop() parentDocument!: any

    private templateOptions: { label: string, value: boolean}[] = [
        { label: 'No template', value: false },
        { label: 'ING', value: true }
    ]

    private title: string = ''
    private template_id: any = null

    private close() {
        this.title = ''
        this.template_id = null
        this.$emit('closeModal')
    }
    
    private createDocument() {
        const body: any = {
            template_id: this.template_id, 
            name: this.title
        }
        if (this.parentDocument) body.parent_id = this.parentDocument.template_id

        this.$emit('saveNewDocument', body)
    }
}
