
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: { DefaultModal }
})
export default class DeleteItemModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() itemToDelete!: any

    public close() {
        this.$emit('closeModal')
    }
    
    public save() {
        this.$emit('deleteItem', [this.itemToDelete.id])
    }
}
