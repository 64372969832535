
import { Component, Vue, Prop } from 'vue-property-decorator'
import FolderRow from './FolderRow.vue';
import Document from './Document.vue'
import Breadcrumb from '../Breadcrumb/Breadcrumb.vue'
import { findFoldersChildren } from '../../helpers/FolderDocumentHelper'

@Component({
  components: { FolderRow, Breadcrumb, Document }
})
export default class Folder extends Vue {
    @Prop() project!: any
    @Prop() folder!: any
    @Prop() documents!: any[]
    @Prop() currentFolder!: any
    @Prop() selectedFolders!: any
    @Prop() selectedDocuments!: any
    @Prop() showArchived!: boolean
    @Prop() allSelected!: boolean

    private breadcrumb: any[] = []

    private setFoldersChildren() {
        if (this.folder && this.currentFolder) {
            return findFoldersChildren([this.folder], this.currentFolder.id)
        }
        return []
    }

    private goBack() {
        this.$emit('setCurrentFolder', this.breadcrumb[this.breadcrumb.length - 2])
    }

    private toggleAllSelection() {
        this.$emit('toggleAllSelection')
    }
}
