
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Modify extends Vue {
    @Prop() private item!: any
    @Prop() private type!: string

    private isOpen: boolean = false

    public setModifyId() {
        this.$emit('updateModify', this.item.id)
    }
    
    private setDocumentToRename() {
        if (this.item.uuid) this.$emit('renameDocument')
        else this.$emit('renameFolder')
    }

    private setFolderToMove() {
        this.$emit('setFolderToMove')
    }

    private setDocumentToMove() {
        this.$emit('moveDocument')
    }

    private toggleArchive() {
        this.$emit('toggleArchive', this.item)
    }

    private openCreateChildModal() {
        this.$emit('openCreateModal', this.item)
    }

    private openDeleteModal() {
        this.$emit('openDeleteModal', this.item)
    }

    private closeList() {
        this.isOpen = false
    }
}
