
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
	components: {}
})
export default class DeleteError extends Vue {
	@Prop() errors: any
	
	private created() {
	}

    private closeErrors() {
        this.$emit('closeErrors')
    }
}
