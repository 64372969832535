
import { Component, Vue, Prop } from 'vue-property-decorator'
import Search from '@/components/inputs/Search.vue'
import SwitchInput from '@/components/inputs/Switch.vue'
import DropdownSort from '@/components/inputs/DropdownSort.vue'
import {flexDocSort} from '@/data/flexDocSort'

@Component({
  components: { Search, SwitchInput, DropdownSort}
})
export default class MultiDocumentsHeader extends Vue {
    @Prop() private sort!: string
    @Prop() private searchQuery!: string
    @Prop() private showArchived!: boolean
    @Prop() private actionButtonsEnabled!: boolean
    @Prop() private archiveButtonEnabled!: boolean

    private sortOptions: SelectItem[] = flexDocSort

    private updateSort(e: any) {
        this.$emit('updateFormControls', { type: 'sort', data: e })
    }

    private updateSwitch(e: any) {
        this.$emit('updateFormControls', { type: 'archived', data: e })
    }
    
    private updateSearchQuery(e: any) {
        this.$emit('updateFormControls', { type: 'search', data: e })
    }

    private openModal(type: string) {
        this.$emit('openModal', type)
    }
}
