
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: { DefaultModal }
})
export default class MoveMultipleModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() currentFolder!: any
    @Prop() selectedFolders!: any[]
    @Prop() flatFoldersList!: any

    private selectedParent: any = null

    private close() {
        this.selectedParent = null
        this.$emit('closeModal')
    }
    
    private save() {
        this.$emit('moveMultipleItems', { parent: this.selectedParent })
    }

    private prepareDocumentsList() {
        let level: any = null
        let disabled: boolean = false
        return this.flatFoldersList.map((item: any) => {
            item.disabled = false

            if (this.selectedFolders.includes(item.id)) {
                level = item.level
                disabled = true
                item.disabled = true
            }

            if (item.level > level) item.disabled = disabled
            if (item.level === level) {
                if (!this.selectedFolders.includes(item.id)) {
                    level = item.level
                    disabled = false
                    item.disabled = false
                }
            }            
            return item
        })
    }
}
