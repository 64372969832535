
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: { DefaultModal }
})
export default class MoveItemModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() folderToMove!: any
    @Prop() flatFoldersList!: any

    private selectedParent: any = null

    private close() {
        this.selectedParent = null
        this.$emit('closeModal')
    }
    
    private save() {
        this.$emit('moveFolder', { folder_ids: [this.folderToMove?.id], parent: this.selectedParent })
    }

    private findParentDocument() {
        const pid = this.folderToMove?.parent_id
        let parent = ''

        if (pid === null) parent = 'All documents'
        else {
            this.flatFoldersList.forEach((item: any) => {
                if (item.id === pid) parent = item.name
            })
        }
        return parent
    }

    private prepareDocumentsList() {
        let level: any = null
        return this.flatFoldersList.map((item: any) => {
            if (level === null && this.folderToMove) {
                if (item.id === this.folderToMove.id) {
                    level = item.level
                    item.disabled = true
                }
                else item.disabled = false
            }
            else {
                if (item.level <= level) {
                    level = null
                    item.disabled = false
                }
                else item.disabled = true
            }
            return item
        })
    }
}
