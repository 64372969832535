import { render, staticRenderFns } from "./ArchiveMultipleModal.vue?vue&type=template&id=6bd272ab&scoped=true&"
import script from "./ArchiveMultipleModal.vue?vue&type=script&lang=ts&"
export * from "./ArchiveMultipleModal.vue?vue&type=script&lang=ts&"
import style0 from "./ArchiveMultipleModal.vue?vue&type=style&index=0&id=6bd272ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd272ab",
  null
  
)

export default component.exports