import moment from 'moment'

export const arrayToTree = (data: any, pid: any = null) => {
    return data.reduce((r: any, e: any) => {
        if (e.parent_id === pid) {
            const obj = { ...e }
            const children = arrayToTree(data, e.id)
            if (children.length) obj.children = children
            r.push(obj)
        }
        return r;
    }, [])
}

export const arrayToLevels = (data: any[]): any[] => {
    data = data.map((item: any) => {
        return {
            ...item,
            id: item.hasOwnProperty('id') ? item.id : item.template_id
        }
    })
    
    function flattenTreeWithLevels(data: any[], level: number = 0) {
		const flatArr: any[] = []

		data.forEach(item => {
			flatArr.push({...item, children: null, level })
			if (item.children && item.children.length) {
				flatArr.push(...flattenTreeWithLevels(item.children, level + 1))
			}
		})
		return flatArr
	}
    
    const tree = arrayToTree(data)
    return flattenTreeWithLevels(tree)
}

export const formatDate = (date: string) => {
    const parts = date.split(' ')
    const dateParts = parts[0].split('-')
    const newDate = `${dateParts[1]}-${dateParts[0]}-${dateParts[2]} ${parts[1]}`
    return moment(newDate).format('DD MMM YYYY HH:mm')
}

export const findFoldersChildren = (folders: any[], id: number) => {
    const children: any[] = []

    folders.forEach(folder => {
        if (folder.id === id && folder.children) children.push(...folder.children)
        
        if (folder.children?.length) children.push(...findFoldersChildren(folder.children, id))
    })
    return children
}

export const findDocumentsChildren = (documents: any[], ids: number[]) => {
    const selected: number[] = []
    const docs = documents.filter((item: any) => item.level === 0 && ids.includes(item.template_id)).map((item: any) => item.template_id)

    function findChildren(id: number) {
        const children: number[] = []
        let level: any = null

        documents.forEach((doc: any) => {
            if (doc.template_id === id) level = doc.level
            else {
                if (level !== null) {
                    if (doc.level > level) children.push(doc.template_id)
                    else level = null
                }
            }
        })
        return children
    }

    docs.forEach((id: number) => {
        selected.push(id)
        selected.push(...findChildren(id))
    })

    return selected
}

export const docsWithPath = (documents: any[], path: any[] = []) => {
    const complete: any[] = []

    documents.forEach((item: any) => {
        const newPath = [...path, { name: item.name, id: item.id }]
        if (item.documents?.length) {
            item.documents.forEach((doc: any) => {
                complete.push({
                    name: doc.title,
                    template_id: doc.template_id,
                    updated_at: item.updated_at,
                    path: newPath
                })
            })
        }
        if (item.children?.length) complete.push(...docsWithPath(item.children, newPath))
    })
    return complete
}

export const sortBy = (items: any[] = [], name: string, dir: string) => {
    return items.sort((a: any, b: any) => {
        if (name !== 'updated_at') {
            if (a[name] > b[name]) return dir === 'asc' ? 1 : -1
            else if (a[name] < b[name]) return dir === 'asc' ? -1 : 1
            else return 0
        }
        else {
            const _a = moment(formatDate(a[name]))
            const _b = moment(formatDate(b[name]))         

            return dir === 'asc' ? moment(_a).diff(_b) : moment(_b).diff(_a)
        }
    })
}

export const findCurrentFolder = (current: any, folders: any[]) => {
    
    function findFolder(items: any[]) {
        let currentFolder: any[] = []

        items.forEach((item: any) => {
            if (item.id === current.id) currentFolder.push(item)
            else {
                if (item.children?.length) currentFolder.push(...findFolder(item.children))
            }
        })
        return currentFolder
    }

    const found = findFolder(folders)
    return found[0] || null
}