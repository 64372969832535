
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ProjectResource } from '@/models/projects/ProjectResource'
import { MultipleDocumentsService } from '@/services/multipleDocuments'
import { 
    arrayToTree, 
    arrayToLevels, 
    findDocumentsChildren, 
    docsWithPath, 
    sortBy,
    findCurrentFolder
} from './helpers/FolderDocumentHelper' 

import MultiDocumentsHeader from './components/MultiDocumentsHeader.vue'
import ModalsContainer from './components/ModalsContainer/ModalsContainer.vue'

import Folder from './components/Folder/Folder.vue'
import DeleteError from './components/Errors/DeleteError.vue'
import SearchResults from './components/SearchResults/SearchResults.vue'

@Component({
  components: { MultiDocumentsHeader, ModalsContainer, Folder, DeleteError, SearchResults }
})
export default class MultiDocuments extends Vue {
	@Prop() private readonly project!: ProjectResource

    private multipleDocumentsService: MultipleDocumentsService = new MultipleDocumentsService({ project_id: this.project.id })

    private showArchived: boolean = false
    private sort: string = 'name-asc'
    private selectedItems: any[] = []
    private searchQuery: string = ''

    private modalCreateFolder: boolean = false
    private modalCreateDocument: boolean = false
    private modalRenameFolder: boolean = false
    private modalRenameDocument: boolean = false
    private modalMove: boolean = false
    private modalMoveDocument: boolean = false
    private modalMoveMultiple: boolean = false
    private modalArchive: boolean = false
    private modalDelete: boolean = false

    private modalLoading: boolean = false
    private mainLoading: boolean = false

    private folder: any = null
    private folderToRename: any = null
    private folderToMove: any = null
    private docToRename: any = null
    private docToMove: any = null
    private itemToDelete: any = null
    private currentFolder: any = null
    private documents: any[] = []
    private oryginalDocuments: any[] = []
    private parentDocument: any = null
    private flatFoldersList: any[] = []
    private selectedFolders: any[] = []
    private selectedDocuments: any[] = []
    private documentsWithPath: any[] = []

    private notEmptyDeleteErrors: any[] = []

    private created() {
        this.getAllData()
        // this.getFolders()
    }

    private get actionButtonsEnabled() {
        return this.selectedDocuments.length > 0 || this.selectedFolders.length > 0
    }

    private get isSearchResultsVisible() {
        return this.searchQuery?.length
    }
    
    private get allSelected() {
        if ((!this.currentFolder || !this.currentFolder?.children?.length) && !this.documents.length) return false

        const foldersNotSelected = this.currentFolder.children?.filter((item: any) => !this.selectedFolders.includes(item.id))
        const docsNotSelected = this.documents.filter((item: any) => !this.selectedDocuments.includes(item.id))

        return (!foldersNotSelected || !foldersNotSelected.length) && !docsNotSelected.length
    }

    private async getAllData(keepCurrent: boolean = false) {
        this.mainLoading = true
        const { data } = await this.multipleDocumentsService.getAllDocuments()
        
        const folders = data.data.filter((item: any) => item.id !== null)
        this.flatFoldersList = arrayToLevels(folders)

        this.folder = data.data.find((item: any) => item.id === null);

        this.folder.children = arrayToTree(folders) 
        this.folder.name = 'All documents'

        this.documentsWithPath = docsWithPath([this.folder])

        if (!keepCurrent) {
            this.currentFolder = this.folder
        }
        else {
            const current = findCurrentFolder(this.currentFolder, [this.folder])
            if (current) this.currentFolder = current
        }
        this.getDocuments()
        this.mainLoading = false         
    }

    // private async getFolders(keepCurrent: boolean = false) {
    //     this.mainLoading = true
    //     const { data } = await this.multipleDocumentsService.getFolders()

    //     this.flatFoldersList = arrayToLevels(data.data)

    //     this.folder = {
    //         id: null,
    //         name: 'All documents',
    //         children: arrayToTree(data.data)
    //     }
    //     if (!keepCurrent) {
    //         this.currentFolder = this.folder
    //         this.getDocuments()
    //     }
    //     this.mainLoading = false
    // }

    private async getDocuments() {
        this.oryginalDocuments = arrayToLevels(this.currentFolder.documents) 
        this.documents = [...this.oryginalDocuments]
        this.sortData()

        // this.mainLoading = true
        // const folderId = this.currentFolder.id === null ? 'root' : this.currentFolder.id
        // const { data } = await this.multipleDocumentsService.getDocumentsList(folderId)
        // this.oryginalDocuments = arrayToLevels(data) 
        // this.documents = [...this.oryginalDocuments]
        // this.mainLoading = false
    }

    private openModal(type: string) {
        switch (type) {
            case 'folder':
                return this.modalCreateFolder = true
            case 'document':
                return this.modalCreateDocument = true
            case 'child-document':
                return this.modalCreateDocument = true
            case 'move':
                return this.modalMove = true
            case 'move-multi':
                return this.modalMoveMultiple = true
            case 'archive-multi':
                return this.modalArchive = true
            case 'delete':
                this.modalDelete = true
            default:
                return
        }
    }

    private sortData() {
        const children = this.currentFolder.children || []
        const documents = [...this.documents]

        if (this.sort === 'name-desc') {
            this.currentFolder.children = sortBy(children, 'name', 'desc')
            this.documents = sortBy(documents, 'title', 'desc')
        }
        else if (this.sort === 'name-asc') {
            this.currentFolder.children = sortBy(children, 'name', 'asc')
            this.documents = sortBy(documents, 'title', 'asc')
        }        
        else if (this.sort === 'created_at-asc') {
            this.currentFolder.children = sortBy(children, 'updated_at', 'asc')
            this.documents = sortBy(documents, 'updated_at', 'asc')            
        }
        else if (this.sort === 'created_at-desc') {
            this.currentFolder.children = sortBy(children, 'updated_at', 'desc')
            this.documents = sortBy(documents, 'updated_at', 'desc')            
        }
        else {}
    }

    private updateFormControls(event: any) {
        switch (event.type) {
            case 'search':
                this.searchQuery = event.data
                return 
            case 'sort':
                this.sort = event.data
                this.sortData()
                return 
            case 'archived':
                return this.showArchived = event.data
            default:
                return
        }
    }

    private closeModals() {
        this.modalCreateFolder = false
        this.modalCreateDocument = false
        this.modalRenameFolder = false
        this.modalRenameDocument = false
        this.modalMove = false
        this.modalMoveDocument = false
        this.modalMoveMultiple = false
        this.modalArchive = false
        this.modalDelete = false
        this.parentDocument = null
    }

    private updateCurrentFolder(current: any) {
        this.currentFolder = current
        this.selectedDocuments = []
        this.selectedFolders = []
        this.documents = []
        this.getDocuments()
    }

    private isPageEmpty() {
        return !this.documents.length && (!this.currentFolder || !this.currentFolder.children || !this.currentFolder.children.length)
    }

    private async saveNewFolder(name: string) {
        this.modalLoading = true
        const body: any = { name }

        if (this.currentFolder.id !== null) {
            body.parent_id = this.currentFolder.id
        }

        await this.multipleDocumentsService.createFolder(body)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }
    
    private async saveNewDocument(data: any) {
        const body: any = {
			template: data.template_id,
			title: data.name,
            folder_id: this.currentFolder.id
		} 

        if (data.parent_id) body.parent_id = data.parent_id

        this.modalLoading = true        
        await this.multipleDocumentsService.createNewDocument(body)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }

    private toggleSelection(data: {type: string, val: number}) {
        const isFolder = data.type === 'folder'
        let items = isFolder ? [...this.selectedFolders] : [...this.selectedDocuments]
        
        if (items.includes(data.val)) items = items.filter((item: number) => item !== data.val)
        else items.push(data.val)

        if (isFolder) this.selectedFolders = items
        else this.selectedDocuments = items
    }

    private toggleAllSelection() {
        const isChecked = this.allSelected

        this.selectedDocuments = []
        this.selectedFolders = []
        
        if (!isChecked) {
            if (this.currentFolder?.children) this.selectedFolders = this.currentFolder.children.map((item: any) => item.id)
            this.selectedDocuments = this.documents.map((item: any) => item.id) 
        }
    }

    private openCreateChildModal(doc: any) {
        this.modalCreateDocument = true
        this.parentDocument = doc
    }

    private openRenameFolderModal(fld: any) {
        this.modalRenameFolder = true
        this.folderToRename = fld
    }

    private openRenameDocumentModal(doc: any) {
        this.modalRenameDocument = true
        this.docToRename = doc
    }
    
    private openMoveFolderModal(fld: any) {
        this.modalMove = true
        this.folderToMove = fld
    }
    
    private openMoveDocumentModal(doc: any) {
        this.modalMoveDocument = true
        this.docToMove = doc
    }

    private async renameFolder(data: any) {
        this.modalLoading = true

        await this.multipleDocumentsService.renameFolder({ name: data.title }, data.id)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }
    
    private async renameDocument(data: any) {
        this.modalLoading = true
        
        await this.multipleDocumentsService.updateDocumentTitle(data.doc.template_id, data.doc.uuid, data.title)
        this.modalLoading = false
        this.closeModals()
        this.getAllData()
    }
    
    private async moveFolder(data: any) {
        this.modalLoading = true

        const body = {
            target_folder_id: data.parent.id,
            template_ids: [],
            folder_ids: data.folder_ids
        }        

        await this.multipleDocumentsService.moveItems(body)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }
    
    private async moveDocument(data: any) {
        
        const body = {
            target_folder_id: data.folder_id,
            template_ids: data.template_ids,
            folder_ids: []
        }        
        
        await this.multipleDocumentsService.moveItems(body)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }
    
    private async moveMultipleItems(data: any) {
        this.modalLoading = true

        const body = {
            target_folder_id: data.parent.id,
            template_ids: findDocumentsChildren(this.documents, this.selectedDocuments),
            folder_ids: [...this.selectedFolders]
        }

        await this.multipleDocumentsService.moveItems(body)
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
        this.getDocuments()    
    }

    private openDeleteItemModal(item: any) {
        this.itemToDelete = item
        this.modalDelete = true
    }

    private async deleteFolder(folder_ids: number[]) {
        this.modalLoading = true

        const { data } = await this.multipleDocumentsService.deleteFolders({ folder_ids })
        this.notEmptyDeleteErrors = data.not_empty
        
        this.modalLoading = false
        this.closeModals()
        this.getAllData(true)
    }

    private async toggleArchive(doc: any) {
        this.mainLoading = true

        const body = {
            template_ids: [doc.template_id],
            folder_ids: [],
            action: doc.archived ? 'unarchive' : 'archive'
        }

        const { data } = await this.multipleDocumentsService.toggleArchive(body)		
        this.mainLoading = false
        this.getAllData()
    }

    private async archiveMultipleItems() {
        this.mainLoading = true 

        const body = {
            template_ids: this.selectedDocuments,
            folder_ids: this.selectedFolders,
            action: 'archive'
        }

        const { data } = await this.multipleDocumentsService.toggleArchive(body)		
        this.mainLoading = false
        this.closeModals()
        this.selectedDocuments = []
        this.selectedFolders = []
        this.getDocuments()
    }
}
