
import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDate } from '../../helpers/FolderDocumentHelper'

@Component({
  components: {}
})
export default class SearchResults extends Vue {
    @Prop() private projectId!: any[]
    @Prop() private documents!: any[]
    @Prop() private searchQuery!: string

    private findByQuery() {
        return this.documents.filter((doc: any) => {
            let isFolder = false

            doc.path.forEach((folder: any) => {
                if (folder.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                    isFolder = true
                }
            })
            return doc.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || isFolder
        })
    }

    private formatModifyDate(date: string) {
        return formatDate(date)
    }
}
