
import { Component, Vue, Prop } from 'vue-property-decorator'
import CreateDocumentModal from './CreateDocumentModal.vue'
import CreateFolderModal from './CreateFolderModal.vue'
import RenameFolderModal from './RenameFolderModal.vue'
import MoveItemModal from './MoveItemModal.vue'
import MoveMultipleModal from './MoveMultipleModal.vue'
import MoveDocumentModal from './MoveDocumentModal.vue'
import DeleteItemModal from './DeleteItemModal.vue'
import RenameDocumentModal from './RenameDocumentModal.vue'
import ArchiveMultipleModal from './ArchiveMultipleModal.vue'

@Component({
  components: { 
    CreateDocumentModal, 
    CreateFolderModal, 
    RenameFolderModal, 
    MoveItemModal, 
    MoveMultipleModal,
    DeleteItemModal,
    RenameDocumentModal,
    MoveDocumentModal,
    ArchiveMultipleModal
  }
})
export default class ModalsContainer extends Vue {
    @Prop() modalCreateFolder!: boolean
    @Prop() modalCreateDocument!: boolean
    @Prop() modalMove!: boolean
    @Prop() modalMoveDocument!: boolean
    @Prop() modalMoveMultiple!: boolean
    @Prop() modalArchive!: boolean
    @Prop() modalDelete!: boolean
    @Prop() modalDeleteMultiple!: boolean
    @Prop() modalRenameFolder!: boolean
    @Prop() modalRenameDocument!: boolean
    @Prop() loading!: boolean
    @Prop() currentFolder!: any
    @Prop() folderToRename!: any
    @Prop() folderToMove!: any
    @Prop() docToRename!: any
    @Prop() docToMove!: any
    @Prop() parentDocument!: any
    @Prop() itemToDelete!: any
    @Prop() flatFoldersList!: any
    @Prop() allSelected!: boolean
    @Prop() selectedFolders!: any[]
    @Prop() selectedDocuments!: any[]
    @Prop() documents!: any[]
}
