
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'

@Component({
  components: { DefaultModal }
})
export default class ArchiveMultipleModal extends Vue {
    @Prop() open!: boolean
    @Prop() loading!: boolean
    @Prop() selectedDocuments!: any[]
    @Prop() selectedFolders!: any[]

    private close() {
        this.$emit('closeModal')
    }
    
    private save() {
        this.$emit('archiveMultipleItems')
    }
}
