
import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDate } from '../../helpers/FolderDocumentHelper'
import Modify from '../Modify/Modify.vue'

@Component({
  components: { Modify }
})
export default class FolderRow extends Vue {
    @Prop() folder!: any
    @Prop() showArchived!: boolean
    @Prop() selectedFolders!: any[]

    private formatModifyDate(date: string) {
        return formatDate(date)
    }

    private setCurrentFolder() {
        this.$emit('setCurrentFolder', this.folder)
    }

    private openCreateModal() {

    }

    private toggleArchive() {

    }

    private selectFolder(id: number) {
        this.$emit('toggleSelection', { type: 'folder', val: id })        
    }

    private renameFolder() {
        this.$emit('openRenameFolderModal', this.folder)        
    }
    
    private moveFolder() {
        this.$emit('openMoveFolderModal', this.folder)        
    }

    private deleteFolder() {
        this.$emit('openDeleteModal', this.folder)
    }
}
