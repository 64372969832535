
import { Component, Vue, Prop } from 'vue-property-decorator'
import { formatDate } from '../../helpers/FolderDocumentHelper'
import Modify from '../Modify/Modify.vue'

@Component({
  components: { Modify }
})
export default class Document extends Vue {
	@Prop() project!: any
	@Prop() document!: any
	@Prop() showArchived!: boolean
	@Prop() selectedDocuments!: any

	private formatModifyDate(date: string) {
        return formatDate(date)
    }

	private openCreateModal() {
		this.$emit('openCreateChildModal', this.document)
	}

	private toggleArchive() {
		this.$emit('toggleArchive', this.document)
	}

	private renameDocument() {
		this.$emit('openRenameDocumentModal', this.document)    
	}
	
	private moveDocument() {
		this.$emit('openMoveDocumentModal', this.document)    
	}

	private selectDocuments(id: number) {
        this.$emit('toggleSelection', { type: 'document', val: id })        
    }
}
